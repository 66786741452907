exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blockchain-security-js": () => import("./../../../src/pages/blockchain-security.js" /* webpackChunkName: "component---src-pages-blockchain-security-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cloud-security-controls-csc-js": () => import("./../../../src/pages/cloud-security-controls-csc.js" /* webpackChunkName: "component---src-pages-cloud-security-controls-csc-js" */),
  "component---src-pages-cybersecurity-consulting-appointments-js": () => import("./../../../src/pages/cybersecurity-consulting-appointments.js" /* webpackChunkName: "component---src-pages-cybersecurity-consulting-appointments-js" */),
  "component---src-pages-cybersecurity-consulting-contact-us-js": () => import("./../../../src/pages/cybersecurity-consulting-contact-us.js" /* webpackChunkName: "component---src-pages-cybersecurity-consulting-contact-us-js" */),
  "component---src-pages-cybersecurity-consulting-faq-js": () => import("./../../../src/pages/cybersecurity-consulting-faq.js" /* webpackChunkName: "component---src-pages-cybersecurity-consulting-faq-js" */),
  "component---src-pages-cybersecurity-consulting-partners-js": () => import("./../../../src/pages/cybersecurity-consulting-partners.js" /* webpackChunkName: "component---src-pages-cybersecurity-consulting-partners-js" */),
  "component---src-pages-cybersecurity-consulting-privacy-policy-js": () => import("./../../../src/pages/cybersecurity-consulting-privacy-policy.js" /* webpackChunkName: "component---src-pages-cybersecurity-consulting-privacy-policy-js" */),
  "component---src-pages-cybersecurity-consulting-services-js": () => import("./../../../src/pages/cybersecurity-consulting-services.js" /* webpackChunkName: "component---src-pages-cybersecurity-consulting-services-js" */),
  "component---src-pages-cybersecurity-consulting-why-us-js": () => import("./../../../src/pages/cybersecurity-consulting-why-us.js" /* webpackChunkName: "component---src-pages-cybersecurity-consulting-why-us-js" */),
  "component---src-pages-cybersecurity-pricing-js": () => import("./../../../src/pages/cybersecurity-pricing.js" /* webpackChunkName: "component---src-pages-cybersecurity-pricing-js" */),
  "component---src-pages-cybersecurity-resources-js": () => import("./../../../src/pages/cybersecurity-resources.js" /* webpackChunkName: "component---src-pages-cybersecurity-resources-js" */),
  "component---src-pages-cybersecurity-training-awareness-js": () => import("./../../../src/pages/cybersecurity-training-awareness.js" /* webpackChunkName: "component---src-pages-cybersecurity-training-awareness-js" */),
  "component---src-pages-data-security-privacy-dsp-js": () => import("./../../../src/pages/data-security-privacy-dsp.js" /* webpackChunkName: "component---src-pages-data-security-privacy-dsp-js" */),
  "component---src-pages-devsecops-dso-js": () => import("./../../../src/pages/devsecops-dso.js" /* webpackChunkName: "component---src-pages-devsecops-dso-js" */),
  "component---src-pages-governance-risk-compliance-grc-js": () => import("./../../../src/pages/governance-risk-compliance-grc.js" /* webpackChunkName: "component---src-pages-governance-risk-compliance-grc-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-security-js": () => import("./../../../src/pages/iot-security.js" /* webpackChunkName: "component---src-pages-iot-security-js" */),
  "component---src-pages-marketplace-index-js": () => import("./../../../src/pages/marketplace/index.js" /* webpackChunkName: "component---src-pages-marketplace-index-js" */),
  "component---src-pages-penetration-testing-pt-js": () => import("./../../../src/pages/penetration-testing-pt.js" /* webpackChunkName: "component---src-pages-penetration-testing-pt-js" */),
  "component---src-pages-process-risk-controls-prc-js": () => import("./../../../src/pages/process-risk-controls-prc.js" /* webpackChunkName: "component---src-pages-process-risk-controls-prc-js" */),
  "component---src-pages-security-architecture-sa-js": () => import("./../../../src/pages/security-architecture-sa.js" /* webpackChunkName: "component---src-pages-security-architecture-sa-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-threat-modeling-tm-js": () => import("./../../../src/pages/threat-modeling-tm.js" /* webpackChunkName: "component---src-pages-threat-modeling-tm-js" */),
  "component---src-pages-virtual-ciso-services-vciso-js": () => import("./../../../src/pages/virtual-ciso-services-vciso.js" /* webpackChunkName: "component---src-pages-virtual-ciso-services-vciso-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/BlogDetail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-marketplace-listing-js": () => import("./../../../src/templates/MarketplaceListing.js" /* webpackChunkName: "component---src-templates-marketplace-listing-js" */)
}

