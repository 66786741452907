import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LfMcdgjAAAAAD8EDfFz2KlTRGbqxIrYSzQWuFGq">
            {element}
        </GoogleReCaptchaProvider>
    )
}

const transitionDelay = 1
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = "manual";
  const currentPosition = getSavedScrollPosition(location);
  if(location.action === 'PUSH'){
    window.setTimeout(() => {
      window.scrollTo(...currentPosition)
    }, transitionDelay)
    return false
  }
}